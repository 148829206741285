import './SectionThree.css'

const image = `${process.env.PUBLIC_URL}/Section3.png`

const SectionThree: React.FC = () => {
  return (
    <div id="three_e_21">
      <div id="three_e_22">
        <img id="three_e_25" alt="Section 3" src={image} />
        <div id="three_e_39">
          <div id="three_e_40">
            <p id="three_e_41">3</p>
          </div>
        </div>
        <div id="three_e_24">
          <p id="three_e_27">Export as a code library</p>
          <p id="three_e_26">
            Last, you select components to export and publish them as a React
            library. The developer easily install it and build using the
            building blocks.
          </p>
        </div>
        <div id="three_e_100"></div>
        <div id="three_e_103"></div>
      </div>
    </div>
  )
}

export { SectionThree }
