import React from 'react'
import './App.css'
import { Navbar } from './Navbar/Navbar'
import { Title } from './Title/Title'
import { SectionTwo } from './SectionTwo/SectionTwo'
import { SectionThree } from './SectionThree/SectionThree'
import { SectionOne } from './SectionOne/SectionOne'
import { CTA } from './CTA/CTA'
import Footer from './Footer/Footer'

const App: React.FC = () => {
  return (
    <div className={'app'}>
      <Navbar />
      <Title />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <CTA />
      <Footer />
    </div>
  )
}

export { App }
