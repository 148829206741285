import './Title.css'

const image = `${process.env.PUBLIC_URL}/Headline.png`

const Title: React.FC = () => {
  return (
    <div id="title_e_21">
      <div id="title_e_22">
        <div id="title_e_24">
          <p id="title_e_27">Visual designs that exports to code</p>
          <p id="title_e_26">
            Repaint is a visual design tool that lets you export as a React
            library. Designers get control over the design system. Developers
            get working code without translation.
          </p>
          <div
            id="title_e_29"
            onClick={() => {
              const ctaSection = document.getElementById('cta_e_21')
              if (ctaSection) {
                ctaSection.scrollIntoView({ behavior: 'smooth' })
              }
            }}
          >
            <p id="title_e_28">Join Waitlist</p>
          </div>
        </div>
        <img id="title_e_25" alt="Title" src={image} />
      </div>
    </div>
  )
}

export { Title }
