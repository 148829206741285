import './Footer.css'

const logo = `${process.env.PUBLIC_URL}/RepaintLogoWhite.png`
const twitter = `${process.env.PUBLIC_URL}/Twitter.png`
const linkedin = `${process.env.PUBLIC_URL}/LinkedIn.png`

export default function Footer() {
  return (
    <div id="footer_e_21">
      <div id="footer_e_83">
        <a href="/" id="footer_e_86">
          <img id="footer_e_85" src={logo} alt="Repaint Logo" />
          <p id="footer_e_84">Repaint</p>
        </a>
        <div id="footer_e_90">
          <p
            id="footer_e_87"
            onClick={() => {
              const featuresSection = document.getElementById('one_e_21')
              if (featuresSection) {
                featuresSection.scrollIntoView({ behavior: 'smooth' })
              }
            }}
          >
            Features
          </p>
          <a id="footer_e_88" href="mailto:ben@repaint.com">
            Contact
          </a>
        </div>
        <div id="footer_e_94">
          <a href="https://twitter.com/RepaintHQ">
            <img id="footer_e_95" src={twitter} alt="Twitter" />
          </a>
          <a href="https://www.linkedin.com/company/repainthq">
            <img id="footer_e_96" src={linkedin} alt="LinkedIn" />
          </a>
        </div>
      </div>
      <div id="footer_e_109">
        <div id="footer_e_116">
          <p id="footer_e_113">2023 Repaint. All right reserved.</p>
          <a
            id="footer_e_114"
            href="https://www.freeprivacypolicy.com/live/b0443ea4-8a0c-4cef-ba24-f250ceac620d"
          >
            Privacy Policy
          </a>
          <a
            id="footer_e_115"
            href="https://www.termsofservicegenerator.net/live.php?token=3xcDnaj0eka5s2UG31LO3jo1zmQ1YkIz"
          >
            Terms of Service
          </a>
        </div>
      </div>
    </div>
  )
}
